import React, { useState, useEffect } from 'react'
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"


import Layout from "../../components/layout"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import "./FooterStyles.scss"
import { motion, useTransform, useMotionValue, useViewportScroll } from "framer-motion"
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';


type DataProps = {
  site: {
    buildTime: string
  }
}

const Footer: React.FC<PageProps<DataProps>> = ({ path }) => {
  const data = useStaticQuery(
    graphql`
    query {
      logo_footer: file(relativePath: { eq: "logo-footer.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:250
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      peer_logo_new: file(relativePath: { eq: "pharma-peer-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `
  )


  const logo_footer = getImage(data.logo_footer)
  const peer_logo_new = getImage(data.peer_logo_new)
  // Use like this:xTO GO <br/>ABOVE <br />AND BEYOND <br/>NORMAL LIMITS

  return (
    <section id="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="logo-container">
              <Link to="/">
                <GatsbyImage
                  image={peer_logo_new}
                  alt="Logo Footer"
                  className="logo-footer"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-12 footer-links">
            <a href="/">
              <span className="footer-menu text-white">Home</span>
            </a>
            <a href="/#why-join-us">
              <span className="footer-menu text-white">Why Join Us</span>
            </a>
            <a href="/#how-it-works">
              <span className="footer-menu text-white">How It Works</span>
            </a>
            <a href="/#our-privacy-promise">
              <span className="footer-menu text-white">Privacy</span>
            </a>
            <a href="/terms-and-conditions">
              <span className="footer-menu text-white">Terms and Conditions</span>
            </a>
            <a href="/#time-investment">
            <span className="footer-menu text-white">Time Investment</span>
            </a>
            <a href="/#cost">
              <span className="footer-menu text-white">Cost</span>
            </a>
          </div>
          {/* <div className="site-footer-container">
            <div className="row">
              <div className="col-lg-6">
                <Link to="#">
                  <span className="text-white footer-links">Privacy Policy</span>
                </Link>
                <Link to="#">
                  <span className="text-white footer-links">Terms and Condition</span>
                </Link>
              </div>
              <div className="col-lg-6 text-right">
                <span className="text-white footer-links">Follow Us</span>
                <a href="#" target="_blank">
                  <FaFacebookF className="social-links"/>
                </a>
                <a href="#" target="_blank">
                  <FaInstagram className="social-links"/>
                </a>
                <a href="#" target="_blank">
                  <FaTwitter className="social-links"/>
                </a>
              </div>
            </div>
          </div> */}
          <div className="col-lg-12">
            <p className="text-white site-footer-text text-center">Copyright {new Date().getFullYear()} . <strong>Peer Club</strong></p>
          </div>
        </div>
      </div>
    </section>
  )

}

export default Footer
