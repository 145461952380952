import React, { Component } from 'react';
import { Link } from 'gatsby'
import { FaTimes } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';
import "./style.scss"


class MobileDropdown extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      hideMenu: true,
    };

    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  hideMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: false });
  }

  render() {
    return (
      <div>
        {
          this.state.showMenu
            ? (
              <button className="mobile-nav text-center" onClick={this.hideMenu}>
                <FaTimes size="21px" className="svg-icon-middle " />
              </button>
            )
            : (
              <button className="mobile-nav text-center" onClick={this.showMenu}>
                <FaBars size="21px" className="svg-icon-middle " />
              </button>
            )
        }
        {
          this.state.showMenu
            ? (
              <div
                className="menu mobile-menu"
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                <div className="hidden-md-up">
                  <ul className="navbar-nav bd-navbar-nav justify-content-end">
                    <li
                    // className={
                    //   location.pathname === '/'
                    //     ? 'nav-item active'
                    //     : 'nav-item'
                    // }
                    >
                      <Link to="#why-join-us" className="nav-link">
                        Why Join Us
                      </Link>
                    </li>
                    <li
                    // className={
                    //   location.pathname === '/'
                    //     ? 'nav-item active'
                    //     : 'nav-item'
                    // }
                    >
                      <Link to="#how-it-works" className="nav-link">
                        How It Works
                      </Link>
                    </li>
                    <li
                    //     className={
                    //     location.pathname === '/'
                    //       ? 'nav-item active'
                    //       : 'nav-item'
                    // }
                    >
                      <Link to="#privacy" className="nav-link">
                        Privacy
                      </Link>
                    </li>
                    <li
                    //     className={
                    //     location.pathname === '/'
                    //       ? 'nav-item active'
                    //       : 'nav-item'
                    // }
                    >
                      <Link to="/terms-and-conditions" target="_blank" className="nav-link">
                        Terms and Conditions
                      </Link>
                    </li>
                    <li
                    //     className={
                    //     location.pathname === '/'
                    //       ? 'nav-item active'
                    //       : 'nav-item'
                    // }
                    >
                      <Link to="#time-investment" className="nav-link">
                        The Investment
                      </Link>
                    </li>
                    <li
                    //     className={
                    //     location.pathname === '/'
                    //       ? 'nav-item active'
                    //       : 'nav-item'
                    // }
                    >
                      <Link to="#cost" className="nav-link">
                        Cost
                      </Link>
                    </li>
                    {/* <li
                    //     className={
                    //     location.pathname === '/'
                    //       ? 'nav-item active'
                    //       : 'nav-item'
                    // }
                    >
                    <a href="https://peer-club-sp3.web.app/" target="_blank">
                      <button className="sp-btn">SOLUTIONS PROVIDER</button>
                    </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            )
            : (
              null
            )
        }
      </div>
    );
  }
}

export default MobileDropdown
